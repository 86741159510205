<template>
  <div class="clientes">
    <h1 class="titulo-card fs-4">Clientes / {{tituloAcao}}</h1>
    <div class="form-group card-principal">
      <div class="d-flex justify-content-between">
        <div class="input-group mb-3">
          <button v-on:click="$router.go(-1)" class="btn btn-outline-primary">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width class="me-1"/>Voltar
          </button>
        </div>

        <div class="input-group mb-3">
          
        </div>
      </div>
      <div v-if="tituloAcao" class="form-group">
        <div class="alert alert-info" role="alert">Você deve especificar o IP válido por onde sai sua comunicação com o SGM Cloud. O campo senha você pode inserir qualquer senha de sua preferência, lembre-se de configurar a mesma senha no seu servidor de autenticação.</div>
        <div class="row">
          <div class="col-6">
            <label class="required">IP</label>
            <input type="text" v-model="radiusIP.ip" class="form-control">
          </div>
          <div class="col-6">
            <label class="required">Identificação</label>
            <input type="text" v-model="radiusIP.description" class="form-control">
          </div>
        </div>

        <div class="d-flex justify-content-end py-2">
          <button type="button" v-if="radiusIP.id" @click="handleRemoveCliente()" class="btn btn-outline-danger me-2">
            <font-awesome-icon :icon="['fas', 'trash']"/>
            Excluir
          </button>
          <button type="button"
              v-if="!radiusIP.id"
              v-on:click="handleModalClienteCadastrar()"
              v-bind:disabled="!podeSalvarIP()"
              class="btn btn-success">
            <font-awesome-icon :icon="['fas', 'check']"/>
            Cadastrar
          </button>
          <button type="button"
              v-if="!!radiusIP.id"
              v-on:click="handleSalvar()"
              v-bind:disabled="!podeSalvarIP()"
              class="btn btn-success">
            <font-awesome-icon :icon="['fas', 'check']"/>
            Salvar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'

export default {
  name: 'IspRadiusIPEdit',
  components: {
  },
  data() {
    return {
      tituloAcao: '',
      ipID: undefined,
      radiusIP: {
        id: undefined,
        ip: '',
        description: '',
      },
    }
  },
  methods: {
    initNewCustomer() {
      this.tituloAcao = 'Cadastrar';
    },

    initEditCustomer() {
      this.tituloAcao = 'Editar';

      api.get(`/isp/radius-ips/${this.ipID}`).then(res => {
        loading(false);
        this.radiusIP = res.data;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    podeSalvarIP() {
      switch (true) {
        case (this.radiusIP.ip.length < 7):
        case (this.radiusIP.description.length < 3):
          return false;

        default:
          return true;
      }
    },

    handleRemoveCliente() {
      dialogo.confirmacao(`Confirma remover o IP <b>${this.radiusIP.ip}</b>?`).then(() => {
        loading(true);

        api.delete(`/isp/radius-ips/${this.radiusIP.id}`).then(() => {
          loading(false);

          this.$router.go(-1);
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    handleModalClienteCadastrar() {
      loading(true);
      api.post('/isp/radius-ips', this.radiusIP).then(() => {
        loading(false);
        this.$router.go(-1);
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    handleSalvar() {
      loading(true);
      api.put(`/isp/radius-ips`, this.radiusIP).then(() => {
        loading(false);
        this.$router.go(-1);
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },
  },
  created() {
    switch (this.$route.name) {
      case 'IspRadiusIPNew':
        this.initNewCustomer();
        break;

      case 'IspRadiusIPEdit':
        this.ipID = this.$route.params.ipID;
        if (!this.ipID) {
          this.$router.push({ path: `/configuracoes/servidores/radius-ips` });
          return;
        }

        this.initEditCustomer();
        break;

      default: {
        this.$router.push({ path: `/configuracoes/servidores/radius-ips` });
      }
    }
  }
}
</script>
